export const generalError = "Unknown error";
export const codeError = "Code is invalid or expired";

// ErrorTypes ---------------------
export const ERRORS = {
  ON_ENTER_EMAIL: {
    USER_NOT_FOUND: "User was not found",
    ACCOUNT_INACTIVE: "Account is inactive",
  },
  ON_SEND_CODE: {
    EMAIL_FAILURE: "Failed to enqueue email",
    ACCOUNT_INACTIVE: "Account is inactive",
    RATE_LIMIT_EXCEEDED: "Rate limit exceeded",
  },
  ON_VALIDATE_CODE: {
    EXPIRED_CODE: "One time use code was expired",
    INVALID_CODE: "The entered code is invalid",
    NOT_FOUND_CODE: "One time use code wasn't found",
  },
  ON_VALIDATE_SSO_CODE: {
    MISMATCH_CODE: "SSO email mismatch with portal email",
    NOT_FOUND_CODE: "SSO email wasn't found",
  },
};

// Error functions
export const errorIdMessage = (status, id) => `${status}: ${id}`;

export const handleSendCodeError = (plainError, setErrors) => {
  let title = generalError;
  let message =
    "Something went wrong when sending the portal code. Please try refreshing your page or contact an administrator";

  if (plainError.includes(ERRORS.ON_SEND_CODE.EMAIL_FAILURE)) {
    title = "Unable to send code";
    message =
      "Sorry! We were unable to send a code due to technical issues. Please try again later.";
  } else if (plainError.includes(ERRORS.ON_SEND_CODE.ACCOUNT_INACTIVE)) {
    title = "Inactive account";
    message =
      "This account is inactive. Please contact an admin to activate it.";
  } else if (plainError.includes(ERRORS.ON_SEND_CODE.RATE_LIMIT_EXCEEDED)) {
    title = ERRORS.ON_SEND_CODE.RATE_LIMIT_EXCEEDED;
    message = plainError;
  }

  setErrors({
    title: title,
    message: message,
  });
  return { title, message };
};

export const handleEnterEmailError = (plainError, setErrors) => {
  let title = generalError;
  let message =
    "Something went wrong when entering the email login. Please try refreshing your page or contact an administrator";

  if (plainError.includes(ERRORS.ON_ENTER_EMAIL.USER_NOT_FOUND)) {
    title = "Invalid sign in email";
    message = "We could not find a matching sign in email.";
  } else if (plainError.includes(ERRORS.ON_ENTER_EMAIL.ACCOUNT_INACTIVE)) {
    title = "Inactive account";
    message =
      "This account is inactive. Please contact an admin to activate it.";
  } else if (plainError.includes(ERRORS.ON_SEND_CODE.RATE_LIMIT_EXCEEDED)) {
    title = ERRORS.ON_SEND_CODE.RATE_LIMIT_EXCEEDED;
    message = plainError;
  }

  setErrors({
    title: title,
    message: message,
  });
  return { title, message };
};

export const handleValidateCodeError = (plainError, setErrors, setStatus) => {
  let title = generalError;
  let message =
    "Something went wrong when validating the portal code. Please try refreshing your page or contact an administrator";

  if (plainError.includes(ERRORS.ON_VALIDATE_CODE.EXPIRED_CODE)) {
    title = "Secure code has expired";
    message = "Click Resend code to receive a new code.";

    // set flag to true to enable re-send button
    setStatus({
      isCodeExpired: true,
    });
  } else if (plainError.includes(ERRORS.ON_VALIDATE_CODE.INVALID_CODE)) {
    title = "Invalid code";
    message = "The code your entered is invalid. Please try again.";
  } else if (plainError.includes(ERRORS.ON_VALIDATE_CODE.NOT_FOUND_CODE)) {
    title = "Invalid code";
    message = "The code your entered wasn't found. Please try again.";
  }

  setErrors({
    title: title,
    message: message,
  });
  return { title, message };
};

export const handleSsoCodeError = (plainError, setErrors) => {
  let title = generalError;
  let message =
    "Something went wrong in SSO process. Please try refreshing your page or contact an administrator";

  if (plainError.includes(ERRORS.ON_VALIDATE_SSO_CODE.MISMATCH_CODE)) {
    title = "Mismatch on SSO email";
    message =
      "Sorry! The expected email and SSO email are different. Please validate the expected email through SSO. Try again later.";
  } else if (plainError.includes(ERRORS.ON_VALIDATE_SSO_CODE.NOT_FOUND_CODE)) {
    title = "SSO email not found";
    const email = extractParenthesizedText(plainError);
    if (email) {
      message = `We couldn't find this contact email (${email}) for the current portal.`;
    } else {
      message = "We couldn't find this contact email for the current portal.";
    }
  }

  setErrors({
    title: title,
    message: message,
  });
  return { title, message };
};

function extractParenthesizedText(message) {
  const match = message.match(/\(([^)]+)\)/);
  return match ? match[1] : null;
}

import styled from "@emotion/styled";
import { colors, mq } from '../../base';

export const CodeInputContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
`;

export const CodeInputCell = styled.input`
    box-sizing: border-box;
    width: 48px;
    height: 66px;
    border: 1px solid ${(props) => (props.IsOnError && !props.disabled) ? colors['red'] : colors['grey-light']};
    color: ${colors['black']};
    margin: 0 4px;
    text-align: center;
    font-weight: 600 !important;
    font-size: 28px;
    line-height: 36px;    
    outline: none !important;   
    caret-color: ${colors['grey-light']};

    ${mq.mobileL} {
        margin: 0 8px;
    }
`;

export const CodeInputCellContainer = styled.div`
    display: flex;
`;

export const Separator = styled.div`
    min-width: 16px;
    width: 4px; 
    
    ${mq.mobileL} {
        width: 16px;
    }
`;
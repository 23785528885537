import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from "formik";
import { enterCode } from "../withFormik";
import { colors } from '../../base';

import FormCodeInput from '../../components/FormCodeInput';
import FormButton from '../../components/FormButton';
import FormCheck from '../../components/FormCheck';
import FormMessage from '../../components/FormMessage';
import FormError from '../../components/FormError';
import * as s from './EnterCodeForm.styles';
import FormIcon from '../../components/FormIcon';
import { handleSendCodeError } from '../../helpers/errors';
import { removeQueryFromUrl } from '../../helpers/urls';
import FormNav from '../../components/FormNav/FormNav';

const CODE = 'portalCode';
const STAYON = 'stayOn';
const MAX_FAILED_ATTEMPTS = 5;
const TEN_MINUTES = 10 * 60 * 1000;
const RATE_LIMIT_ERROR = 'Rate limit exceeded';

function EnterCodeForm({
  email,
  values,
  setFieldValue,
  status,
  errors,
  setErrors,
  isSubmitting,
  setSubmitting,
  handleChange,
  resetForm,
  attemptCount,
  lastAttemptDateUTC,
  handleSendEmail,
  handleBackPress,
  handleNextPress,
  isBackPressable,
  isNextPressable,
  handleCountdownStop,
}) {
  const [isMaxAttemptsTimerOn, setIsMaxAttemptsTimerOn] = useState(false);
  const [attemptsCountdown, setAttemptsCountdown] = useState('10:00');
  const [resendCountdown, setResendCountdown] = useState('10:00');
  const [isSendCodeTimerOn, setIsSendCodeTimerOn] = useState(false);
  const [endCountdownDateUTC, setEndCountdownDateUTC] = useState(null);

  const getTimeToTryAgain = () => {
    if (errors.title && errors.title.includes(RATE_LIMIT_ERROR)) {
      // get time when client can try again
      const start = errors.message.indexOf('Try at') + 6; // Add 6 to skip past 'at '
      const end = errors.message.indexOf('UTC.');
      const timeString = errors.message.substring(start, end).trim();
      const timestampToTryUTC = new Date(timeString).getTime();
      setEndCountdownDateUTC(timestampToTryUTC);

      // set initial countdown
      const timeRemaining = timestampToTryUTC - Date.now();
      const minutes = Math.floor(timeRemaining / 60000);
      const seconds = Math.floor((timeRemaining % 60000) / 1000);
      setResendCountdown(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
    }
  };

  useEffect(() => {
    getTimeToTryAgain();
  }, [errors.message]);

  useEffect(() => {
    if (!endCountdownDateUTC || endCountdownDateUTC < Date.now()) {
      setIsSendCodeTimerOn(false);
      return;
    }

    const interval = setInterval(() => {
      const timeRemaining = endCountdownDateUTC - Date.now();
      const minutes = Math.floor(timeRemaining / 60000);
      const seconds = Math.floor((timeRemaining % 60000) / 1000);

      if (minutes <= 0 && seconds <= 0) {
        setIsSendCodeTimerOn(false);
        setResendCountdown("0:00");
        clearInterval(interval);
        resetForm();
        handleCountdownStop();
      } else {
        setIsSendCodeTimerOn(true);
        setResendCountdown(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endCountdownDateUTC]);

  useEffect(() => {
    if (MAX_FAILED_ATTEMPTS - attemptCount <= 0) {
      const interval = setInterval(() => {
        const currentDate = Date.now();
        const timeElapsed = currentDate - lastAttemptDateUTC;
        const timeRemaining = TEN_MINUTES - timeElapsed;
        const minutes = Math.floor(timeRemaining / 60000);
        const seconds = Math.floor((timeRemaining % 60000) / 1000);

        if (timeElapsed >= TEN_MINUTES) {
          setIsMaxAttemptsTimerOn(false);
          setAttemptsCountdown('0:00');
          setFieldValue(CODE, "");
          resetForm();
          handleCountdownStop();
        } else {
          setIsMaxAttemptsTimerOn(true);
          setAttemptsCountdown(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [lastAttemptDateUTC, attemptCount]);

  const getAddedErrorMessage = () => {
    if (attemptCount > 0 && !(status && status.isCodeExpired)) {
      return `You have ${(MAX_FAILED_ATTEMPTS - attemptCount) >= 0 ? (MAX_FAILED_ATTEMPTS - attemptCount) : '0'} attempt(s) left.`
    }
  };

  const getErrorTitle = () => {
    if (isFailedAttemptsExceededError()) {
      return 'No more attempts left!';
    } else if (errors && errors.title && errors.title.includes(RATE_LIMIT_ERROR)) {
      return 'Unable to resend code';
    }
    return errors.title;
  };

  const getErrorMessage = () => {
    if (isFailedAttemptsExceededError()) {
      return `Please note that you will have to wait <strong>${attemptsCountdown}</strong> to Resend code and try again.`;
    }
    else if (errors && errors.title && errors.title.includes(RATE_LIMIT_ERROR)) {
      return `You’ve recently requested a code. Please check your spam folder or wait <strong>${resendCountdown}</strong> before requesting another. This helps keep your account secure.`;
    }
    return `${errors.message} ${getAddedErrorMessage()}`;
  };

  const isFailedAttemptsExceededError = () => {
    return (MAX_FAILED_ATTEMPTS - attemptCount) <= 0;
  };

  const handleResendCode = async () => {
    setSubmitting(true);

    const handleSubmitError = (plainError) => {
      handleSendCodeError(plainError, setErrors);
    }

    const reqStatus = await handleSendEmail('', 'email', handleSubmitError, false);

    if (reqStatus >= 200 && reqStatus < 300) {
      removeQueryFromUrl();
      setFieldValue(CODE, "");
      resetForm();
    }

    setSubmitting(false);
  };

  return (
    <>
      <FormNav
        handleBackPress={handleBackPress}
        handleNextPress={handleNextPress}
        isBackPressable={() => isBackPressable() && !((MAX_FAILED_ATTEMPTS - attemptCount) <= 0) && !(status && status.isCodeExpired)}
        isNextPressable={isNextPressable}
      />
      <Form style={{ width: '100%', maxWidth: '100%' }}>
        <FormIcon icon='lock' backgroundSize={71} height={41} width={40} isSelected={false} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
        <s.MobileFormat>
          <FormMessage
            className='enterCodeText'
            fontWeight={600}
            style={{ marginBottom: '8px', color: colors['grey-darker'] }}
          >
            Enter secure code
          </FormMessage>
          {errors && errors.title && (!errors.title.includes(RATE_LIMIT_ERROR) || !!endCountdownDateUTC)
            && (
              <FormError
                errorMessage={getErrorTitle()}
                errorId={getErrorMessage()}
              />
            )}
          <FormMessage
            className='formMessageText'
            fontWeight={400}
            color={colors['grey']}
            style={{ marginBottom: '4px' }}
          >
            Please enter the 6-digit code sent to {email ? <span style={{ fontWeight: 600, fontSize: 14, color: colors['grey-dark'] }}>{email}</span> : ' your email'}
          </FormMessage>
          <FormMessage
            className='formMessageText'
            fontWeight={400}
            color={colors['grey']}
            style={{ marginBottom: '32px' }}
          >
            Didn&apos;t get it? {email ? <s.SmallLink onClick={handleResendCode}> Resend code </s.SmallLink> : ' Please refresh and try again later.'}
          </FormMessage>

          <FormCodeInput
            value={values[CODE]}
            handleChange={(newValue) => {
              setFieldValue(CODE, newValue);
            }}
            fields={6}
            IsOnError={(!!errors.title && !isSendCodeTimerOn) || isMaxAttemptsTimerOn}
            name="portalCode"
            disabled={isSubmitting || (isMaxAttemptsTimerOn && isFailedAttemptsExceededError())}
          />

          {errors.portalCode && (
            <s.InlineCodeError>
              <span id="InlineErrorIcon" className="mi-v4-validation-icon">
                <svg width="14px" height="14px" viewBox="0 0 1024 1024" preserveAspectRatio="none" fill="red" xmlns="http://www.w3.org/2000/svg">
                  <path d="M499.029 42.667l-8.747 0.085c-131.456 2.261-255.104 55.381-348.288 149.547-93.781 94.805-144.171 219.008-141.909 349.611 4.693 270.421 216.533 482.219 482.347 482.219l8.619-0.043c274.987-4.821 494.933-228.779 490.197-499.243-4.693-270.379-216.491-482.176-482.219-482.176zM490.283 981.419l-7.893 0.043c-242.261 0-435.371-193.408-439.68-440.32-2.091-119.040 43.947-232.277 129.579-318.848 85.291-86.229 198.443-134.827 318.72-136.917l8.021-0.043c242.219 0 435.243 193.365 439.552 440.235 4.309 246.955-196.821 451.456-448.299 455.851zM490.667 618.667c11.776 0 21.333-9.557 21.333-21.333v-298.667c0-11.776-9.557-21.333-21.333-21.333s-21.333 9.557-21.333 21.333v298.667c0 11.776 9.557 21.333 21.333 21.333z M533.333 746.667c0 23.564-19.103 42.667-42.667 42.667s-42.667-19.103-42.667-42.667c0-23.564 19.103-42.667 42.667-42.667s42.667 19.103 42.667 42.667z" stroke="inherit"></path>
                </svg>
              </span>
              <div id="InlineErrorText">
                Please match the requested format.
              </div>
            </s.InlineCodeError>
          )}

          <FormCheck
            id={STAYON}
            name={STAYON}
            value={values[STAYON]}
            checked={values[STAYON]}
            onChange={handleChange}
            caption='Keep me signed in for 30 days'
          />
          {!(status && status.isCodeExpired) &&
            <FormButton
              type='submit'
              disabled={isSubmitting || !values[CODE] || isFailedAttemptsExceededError()}
              buttonStyle='primary'
              buttonText='Sign in'
            />
          }
          {(status && status.isCodeExpired) &&
            <FormButton
              type='reset'
              disabled={isSubmitting || (MAX_FAILED_ATTEMPTS - attemptCount) <= 0 || isMaxAttemptsTimerOn || isSendCodeTimerOn}
              buttonStyle='primary'
              buttonText='Resend code'
              onClick={handleResendCode}
            />
          }
        </s.MobileFormat>
      </Form>
    </>
  )
}

EnterCodeForm.propTypes = {
  values: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func,
  email: PropTypes.string,
  setFieldValue: PropTypes.func,
  status: PropTypes.instanceOf(Object),
  setErrors: PropTypes.func,
  setSubmitting: PropTypes.func,
  resetForm: PropTypes.func,
  attemptCount: PropTypes.number,
  lastAttemptDateUTC: PropTypes.number,
  handleSendEmail: PropTypes.func,
  handleBackPress: PropTypes.func,
  handleNextPress: PropTypes.func,
  isBackPressable: PropTypes.func,
  isNextPressable: PropTypes.func,
  handleCountdownStop: PropTypes.func,
}

export default enterCode(EnterCodeForm);
import React from 'react';
import * as s from './ErrorMessage.styles';
import PropTypes from 'prop-types';

function ErrorMessage({ errorText, errorId }) {
  return <s.ErrorMessage>
    <s.ErrorTextContainer id='form-message-content' >{errorText}</s.ErrorTextContainer>
    {errorId && (
      <s.ErrorIdContainer id='form-message-id' dangerouslySetInnerHTML={{ __html: errorId }} />
    )}
  </s.ErrorMessage>
}

export default ErrorMessage;

ErrorMessage.propTypes = {
  errorId: PropTypes.string,
  errorText: PropTypes.string
};